<template>
  <section class="containerHiveTooltip">
    <span class="grey-icon-more-info containerHiveTooltip__icon" @mouseover="toggleHiveTooltipVisible" @mouseleave="toggleHiveTooltipVisible(false)"></span>
    <span class="containerHiveTooltip__tooltip" :class="{hiddenTooltip: !isHiveTooltipVisible}" @mouseover="toggleHiveTooltipVisible" @mouseleave="toggleHiveTooltipVisible(false)">
      Take me to <a class="containerHiveTooltip__tooltip--link" href="https://greygrp.sharepoint.com/sites/GreyCOE/" target="_blank">COE</a>
      <span class="grey-icon-arrow-pagination containerHiveTooltip__tooltip--icon"></span>
    </span>
  </section>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'hiveTooltip',
  setup (props) {
    const isHiveTooltipVisible = ref(false)
    const hiveTooltipTimer = ref(null)

    /**
     * @description Toggles the visibility of HIVE tooltip.
     * @param {isVisible} boolean to indicate if tooltip should be visible or not.
     */
    function toggleHiveTooltipVisible (isVisible = true) {
      if (isVisible === isHiveTooltipVisible.value) {
        return
      }
      clearTimeout(hiveTooltipTimer.value)
      hiveTooltipTimer.value = setTimeout(() => {
        isHiveTooltipVisible.value = isVisible
      }, 300)
    }

    return {
      isHiveTooltipVisible,
      hiveTooltipTimer,
      toggleHiveTooltipVisible
    }
  }
}
</script>
