<template>
  <section class="containerWorkField">
    <section>
      <p class="containerWorkField__title"> {{ title }} </p>
      <slot name="icon"></slot>
    </section>
    <ul v-if="isAwards" class="containerWorkField__list">
      <li v-for="(item, index) in value" :key="index" class="containerWorkField__list--item">
        <span class="label main">Show: {{ item.show }} </span>
        <p class="label">Name: {{ item.name }} </p>
        <p class="label">Category: {{ item.category }} </p>
        <p class="label">Year: {{ item.year }} </p>
      </li>
    </ul>
    <span v-else-if="isPerson" class="containerWorkField__containerLinkList">
      <span v-for="(item, index) in value" :key="index" class="containerWorkField__containerLinkList--link">
        <a target="_self"
           :href="item.displayUserInfo ? item.link : false"
           class="link"
           :id="`people-${index}`"
           @mouseover="openPopupPersonalInfo($event, item.displayUserInfo, index, item.ecCode)"
           @mouseleave="openPopupPersonalInfo($event)"
           >{{item.value}}
          <popupPersonalInfoGeneric
            v-if="openPopup && selectedNameId === `people-${index}`"
            :popupPosition="popupPosition"
            :personalInfoProp="store.state.managerInfo"
            :profileLink="item.link"
            :isSearchApp="true"
            @click.stop.prevent>
          </popupPersonalInfoGeneric>
        </a>
        <span v-if="index !== value.length - 1" class="comma">,</span>
      </span>
    </span>
    <div v-else>
      <div v-if="(isLink)">
        <div v-if="(isIndustry)">
          <linkFilter v-for="(item, index) in industryValues" source="Work" :key="index" :label="item" :propertyName="formatWorkTitle"/>
        </div>
        <linkFilter v-else source="Work" :label="formatWorkValue" :propertyName="formatWorkTitle"/>
      </div>
      <p v-else class="containerWorkField__label"> {{ formatWorkValue }} </p>
    </div>
  </section>
</template>

<script>
import { computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import linkFilter from '@/components/linkFilter/linkFilter'

export default {
  name: 'workField',
  props: ['title', 'value'],
  components: {
    linkFilter
  },
  setup (props) {
    const store = useStore()
    const openPopup = ref(false)
    const popupPosition = reactive({
      right: 0,
      top: 0,
      bottom: 0,
      left: 0
    })
    const currentVisitedEcCode = ref(null)
    const selectedNameId = ref(null)
    const timerToShowPopUp = ref(0)

    /**
     * @description Validate if the current title is equal to Industry
     */
    const isIndustry = computed(() => {
      return props.title === 'Industry'
    })

    /**
     * @description Return industry values as Array
     */
    const industryValues = computed(() => {
      return props.value.split(', ')
    })

    /**
     * @description Format field value if apply
     */
    const formatWorkValue = computed(() => {
      let formattedFieldValue = props.value
      switch (props.title) {
        case 'Asset Expiry Date':
        case 'Air Date':
        case 'Shoot Date':
        case 'Music Usage Right Expiry Date':
        case 'Local VO Usage Right Expiry Date':
        case 'Live Date':
          formattedFieldValue = store.getters.formatDate(props.value)
          break
      }
      return formattedFieldValue
    })

    /**
     * @description Validate if the field is awards
     */
    const isAwards = computed(() => {
      return props.title === 'Awards'
    })

    /**
     * @description Format Title value to use on filters
     */
    const formatWorkTitle = computed(() => {
      let formatedTitle = ''
      switch (props.title) {
        case 'Client':
          formatedTitle = 'client'
          break
        case 'Brand':
          formatedTitle = 'brand'
          break
        case 'Agency / Office':
          formatedTitle = 'agency'
          break
        case 'Industry':
          formatedTitle = 'categories'
          break
      }
      return formatedTitle
    })

    /**
     * @description Validate if the field is a link
     */
    const isLink = computed(() => {
      let isLinkVar = false
      const allowedLinks = [
        'Client',
        'Brand',
        'Agency / Office',
        'Industry'
      ]
      allowedLinks.forEach(el => {
        if (props.title === el) {
          isLinkVar = true
        }
      })
      return isLinkVar
    })

    /**
     * @description Validate if the field is a person.
     */
    const isPerson = computed(() => {
      return props.title === 'Collaborators' || props.title === 'Author'
    })

    /**
     * @description Opens/closes the popup with the info of a given user.
     * @param event of DOM
     * @param {boolean} flag to show or hide modal.
     * @param {number} index position in array.
     * @param {string} eccode of user.
     */
    function openPopupPersonalInfo (event, flag, index, eccode) {
      const isMobile = navigator.userAgent.match(/Android|iPhone|iPad/i)
      if (isMobile) {
        return
      }

      currentVisitedEcCode.value = eccode
      clearTimeout(timerToShowPopUp.value)

      if (!flag) {
        clearUserPopUpInfo()
        return
      }

      const popUpAlreadyOpenForThisUser = store.state.managerInfo.namePositionNumber === index || !eccode
      if (popUpAlreadyOpenForThisUser) {
        return
      }

      store.dispatch({
        type: 'getManagerData',
        ecCode: eccode,
        namePositionNumber: index
      }).then(() => {
        if (eccode !== currentVisitedEcCode.value) {
          clearUserPopUpInfo()
          return
        }

        selectedNameId.value = event.target.id

        setPositionOfUserPopUp(event)
        openPopup.value = true
      })
    }

    /**
     * @description Clears the info of the user's popup.
     */
    function clearUserPopUpInfo () {
      timerToShowPopUp.value = setTimeout(() => {
        openPopup.value = false
        popupPosition.value = {}
        store.commit({
          type: 'GET_MANAGER_INFO',
          managerInfo: {},
          namePositionNumber: null
        })
      }, 500)
    }

    /**
     * @description Sets the position of the user's popup based on the free space around the window.
     * @param {event} event of the mouse over trigered by the user.
     */
    function setPositionOfUserPopUp (event) {
      const rect = event.target.getBoundingClientRect()
      const areThereSpaceAtBottom = window.innerHeight - rect.bottom > 210
      const areThereSpaceAtRight = window.innerWidth - rect.left > 430
      const maxSizeAtRight = window.innerWidth - 430 - rect.left - 20

      popupPosition.value = { top: 'none', bottom: 'none', right: 'none', left: 'none' }

      if (areThereSpaceAtBottom) {
        popupPosition.top = '100%'
      } else {
        popupPosition.bottom = '100%'
      }

      if (areThereSpaceAtRight) {
        const leftSize = Math.min(event.clientX - rect.left + 10, maxSizeAtRight)
        popupPosition.left = `${leftSize}px`
      } else {
        popupPosition.left = `${maxSizeAtRight}px`
      }
    }

    return {
      store,
      openPopup,
      popupPosition,
      currentVisitedEcCode,
      selectedNameId,
      timerToShowPopUp,
      isIndustry,
      industryValues,
      formatWorkValue,
      isAwards,
      formatWorkTitle,
      isLink,
      isPerson,
      openPopupPersonalInfo
    }
  }
}
</script>
