<template>
  <section class="containerDetailWork">
    <backToTopButton @scroll-to-top="scrollToTop" :scrollY="scrollY" />
    <section class="containerDetailWork__info">
      <backButton altRedirection="resultsWork" altLabel="WORK" @goBack="goBack"></backButton>
      <section v-if="detailDataLoaded" class="containerDetailWork__info--contentData">
        <div class="elegibleGreyCom" v-if="elegibleForGreyCom">
          <div class="elegibleGreyCom__info">
            <span class="elegibleGreyCom__info--icon">i</span>
            <span class="elegibleGreyCom__info--label">Eligible for Grey.com</span>
          </div>
        </div>
        <div class="detailWorkName">
          <h2 class="label" :title="resultItemWork.title">{{ resultItemWork.title }}</h2>
          <options-card class="title__optionsCard" v-if="resultItemWork.id"
            @openCollectionsModal="openCollectionsModal"
            @openAddToPortfolioModal="openAddToPortfolioModal"
            @openDeleteModal="openDeleteModal"
            :id="resultItemWork.id"
            :source="resultItemWork.source"
          >
          </options-card>
        </div>
        <figure class="detailWorkImage" :class="{'detailWorkVideo' : itemHasStreamingUrls}">
          <section class="detailWorkImage__video" v-if="itemHasStreamingUrls">
            <video id="azuremediaplayer" class="azuremediaplayer amp-default-skin amp-big-play-centered" playsinline tabindex="0"/>
          </section>
          <img class="detailWorkImage__image"
            v-else :src="setAssetToShow"
          >
        </figure>
        <div class="detailWorkReactions">
          <section class="detailWorkReactions__dateRegionContent">
            <div class="detailWorkReactions__dateRegionContent--date">
              <span class="date">{{ resultItemWork.uploadDate }}</span>
            </div>
            <div class="detailWorkReactions__dateRegionContent--region">
              <span class="region" v-if="resultItemWork.extraFields.agencies" :title="store.getters.parseArrayToString(resultItemWork.extraFields.agencies)">{{ store.getters.parseArrayToString(resultItemWork.extraFields.agencies) }}</span>
            </div>
          </section>
          <reactionsBox class="reactions"
            v-if="$store.state.reactions[resultItemWork.id]"
            likesDetail
            @openLikesModal="toggleLikesModal(true)"
            :itemId="route.params.idCard"
          />
        </div>
        <div class="detailWorkDownloadMain"
          @click="downloadFilePreview"
          v-if="store.getters.actionCanBePerformed('download', 'WorkItem')"
          :class="{'disabledEvents': showSpinnerDownloadFile}"
        >
          <span class="detailWorkDownloadMain__icon icon-grey-download_icon" v-if="!showSpinnerDownloadFile"></span>
          <spinner class="detailWorkDownloadMain__spinner" v-else></spinner>
          <span class="detailWorkDownloadMain__label">DOWNLOAD FILE</span>
        </div>
        <section class="detailWorkDownload"
          v-if="store.getters.actionCanBePerformed('download', 'WorkItem')">
          <div class="detailWorkDownload__phone" v-if="store.state.phoneResolution">
            <div class="detailWorkDownload__phone--file" v-for="(file, index) in resultItemWork.supportingFiles" :key="index"
              :class="{'disabledEvents': showSpinnerSupporting(index)}"
              @click.stop.prevent="downloadFile(file, index)">
              <img class="placeHolderFile" :src="file.placeholder"/>
              <div class="downloadFileInformation">
                <span class="downloadFileInformation__downloadFileName">{{file.originalName}}</span>
                <div class="downloadFileInformation__downloadFileAction">
                  <span class="downloadFileInformation__downloadFileAction--label">Download file</span>
                  <spinner class="downloadFileInformation__downloadFileAction--spinner" v-if="showSpinnerSupporting(index)"></spinner>
                  <span class="downloadFileInformation__downloadFileAction--icon icon-grey-download_icon" v-if="showDownloadSupporting(index)"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="detailWorkDownload__notPhone" v-else>
            <div class="detailWorkDownload__notPhone--file" v-for="(file, index) in resultItemWork.supportingFiles" :key="index">
              <div class="placeHolderContainer">
                <img class="placeHolderContainer__file" :src="file.placeholder"/>
              </div>
              <span class="downloadFileName">{{file.originalName}}</span>
              <a class="downloadFileTrigger" :class="{'visible': fileIndexActive === index, 'disabledEvents': showSpinnerSupporting(index)}"
                :href="file.permanentSasUrl"
                @click.stop.prevent="downloadFile(file, index)"
                @mouseover="fileIndexActive = index"
                @mouseleave="fileIndexActive = null">
                <spinner class="downloadFileTrigger__spinner" v-if="showSpinnerSupporting(index)"></spinner>
                <span class="downloadFileTrigger__downloadIcon icon-grey-download_icon" v-if="showDownloadSupporting(index)"></span>
                <span class="downloadFileTrigger__downloadLabel">Download file</span>
              </a>
            </div>
          </div>
        </section>
        <div class="subtitle">
          <span class="subtitle__label">{{resultItemWork.subtitle}}</span>
        </div>
        <div class="description">
          <span class="description__label">{{resultItemWork.description}}</span>
        </div>
        <section class="detailWorkFieldsContainer">
          <div class="detailWorkFieldsContainer__first">
            <workField v-for="(field, index) in firstHalf"
              :key = index
              :title="field.title"
              :value="field.value">
              <template v-slot:icon v-if="field.title === 'Centers of Excellence'">
                <hiveTooltip/>
              </template>
            </workField>
          </div>
          <div class="detailWorkFieldsContainer__second">
            <workField v-for="(field, index) in secondHalf"
              :key = index
              :title="field.title"
              :value="field.value">
              <template v-slot:icon v-if="field.title === 'Centers of Excellence'">
                <hiveTooltip/>
              </template>
            </workField>
          </div>
        </section>
        <commentsBox :articleId="resultItemWork.id" :comments="comments" @updateCommentList="updateCommentList" @updateCommentContent="updateCommentContent">
        </commentsBox>
      </section>
    </section>
    <section v-if="detailDataLoaded" class="containerDetailWork__similarAssets">
      <div class="containerDetailWork__similarAssets--background">
        <similar-assets :articleId="route.params.idCard" stylesFrom="detail"></similar-assets>
      </div>
    </section>
    <skeletonDetailPage v-if="!detailDataLoaded" source="work" />
    <addRemoveItemsToCollectionGeneric
      v-if="store.state.collectionsInfo.isCollectionModalOpened"
      @goToNewCollection="forwardToCreateCollectionModal"
      @close="openCollectionsModal(null, false)"
      :cardsId="[store.state.collectionsInfo.cardId]"
      :collectionsList="store.state.collectionsMine"
      @updateCollectionContainId="updateCollectionContainId($event)">
    </addRemoveItemsToCollectionGeneric>
    <createCollectionModalGeneric
      v-if="createCollectionModalVisible"
      :creatingWithItems="true"
      :cardsId="[resultItemWork.id]"
      :userLogged="store.state.dataUserLogin.email"
      @cancel-action="backToCollectionModal"
      @close="toggleCreateCollectionsModal(false)"
    ></createCollectionModalGeneric>
    <peopleListModalGeneric v-if="likesModalVisible" :itemId="route.params.idCard" @close="toggleLikesModal(false)"></peopleListModalGeneric>
    <addItemToPortfolio ref="portfolioModal"/>
    <deleteItem ref="deleteWorkItemModal"
      :source="resultItemWork.source"
      detailPage
      @showDeleteErrorToast="showDeleteErrorToast"
    />
    <toast v-if="deleteErrorToast" :class="{'marginToolToast': !store.state.isMobile}"
      :message="errorMessageToast">
    </toast>
  </section>
</template>

<script>
import { onMounted, onUnmounted, computed, ref, reactive, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import reactionsBox from '@/components/reactionsBox/reactionsBox'
import workField from '@/components/workField/workField'
import configuration from './detailWorkPage.json'
import optionsCard from '@/components/optionsCard/optionsCard'
import addItemToPortfolio from '@/components/addItemToPortfolioModal/addItemToPortfolioModal'
import deleteItem from '@/components/deleteItemModal/deleteItemModal'
import messages from '../../messages.json'
import { setUpAzureMediaPlayer } from '@/utils/azureMediaService'
import similarAssets from '@/components/similarAssets/similarAssets'
import hiveTooltip from '@/components/hiveTooltip/hiveTooltip'
import commentsBox from '@/components/commentsBox/commentsBox'
import skeletonDetailPage from '@/components/skeletons/skeletonDetailPage/skeletonDetailPage'
import backButton from '@/components/backButton/backButton'
import spinner from '@/components/spinner/spinner'
import $ from 'jquery'

export default {
  name: 'detailWorkPage',
  components: {
    reactionsBox,
    workField,
    optionsCard,
    addItemToPortfolio,
    deleteItem,
    similarAssets,
    hiveTooltip,
    commentsBox,
    skeletonDetailPage,
    backButton,
    spinner
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const fields = ref([])
    const resultItemWork = ref([])
    const detailDataLoaded = ref(false)
    const setAssetToShow = ref('')
    const errorMessageToast = ref(messages.generic.errorMessageToast)
    const deleteErrorToast = ref(null)
    const azureMediaPlayer = reactive({})
    const comments = ref([])
    const likesModalVisible = ref(false)
    const portfolioModal = ref(null)
    const deleteWorkItemModal = ref(null)
    const showSpinnerDownloadFile = ref(false)
    const createCollectionModalVisible = ref(false)
    const fileIndexActive = ref(null)
    const downloadingSupporting = ref([])
    const scrollY = ref(0)

    /**
     * @description get first halt of fields
     */
    const firstHalf = computed(() => {
      return store.state.isMobileResolution ? fields.value : fields.value.slice(0, Math.ceil(fields.value.length / 2))
    })

    /**
     * @description get second half of fields
     */
    const secondHalf = computed(() => {
      return store.state.isMobileResolution ? [] : fields.value.slice(Math.ceil(fields.value.length / 2), fields.value.length)
    })

    /**
     * @description Validate if the file is elegible for grey.com
     */
    const elegibleForGreyCom = computed(() => {
      return resultItemWork.value.submittedToGreyCom && resultItemWork.value.submittedToGreyCom === 'Yes'
    })

    /**
     * @description Returns a flag to know if the current item has the stremaing urls.
     */
    const itemHasStreamingUrls = computed(() => {
      return resultItemWork.value.assets[0].streamingUrl && resultItemWork.value.assets[0].streamingUrl.length
    })

    /**
     * @description Validate if display individual spinner for supporting file.
     * @param index index of selected file
     */
    const showSpinnerSupporting = computed(() => (index) => {
      return downloadingSupporting.value.includes(index) && (store.state.phoneResolution ? true : fileIndexActive.value === index)
    })

    /**
     * @description Validate if display individual download icon for supporting file.
     * @param index index of selected file
     */
    const showDownloadSupporting = computed(() => (index) => {
      return !downloadingSupporting.value.includes(index)
    })

    onMounted(() => {
      setUpDetailPage()
      setBackToTopButton()
    })

    onUnmounted(() => {
      if (azureMediaPlayer.value && Object.values(azureMediaPlayer.value).length > 0) azureMediaPlayer.value.dispose()
    })

    /**
     * @description gets main container to be able to listen to its scroll
     */
    function setBackToTopButton () {
      let mainSpace
      if (window.innerWidth < 1024) {
        mainSpace = document.querySelector('.topSpaceMobile')
      } else {
        mainSpace = document.querySelector('.leftSpaceDesktop')
      }
      mainSpace.addEventListener('scroll', () => {
        scrollY.value = mainSpace.scrollTop
      })
    }

    /**
     * @description scrolls the page to the top
     */
    function scrollToTop () {
      $('html, #app').animate({ scrollTop: 0 }, 500)
    }

    /**
     * @description Init render view
     */
    function setUpDetailPage () {
      store.dispatch({
        type: 'work/getDetailWorkById',
        id: route.params.idCard
      }).then((response) => {
        response.uploadDate = store.getters.formatDate(response.uploadDate)
        resultItemWork.value = response

        store.dispatch({
          type: 'updateViews',
          id: route.params.idCard,
          setLikes: true
        }).then(() => {
          getDetailViewAndComments()
        })
      })
    }

    /**
     * @description Method that response the detail view and comments
     */
    function getDetailViewAndComments () {
      callGetDetailComments().then((detailComments) => {
        comments.value = detailComments.data.comments
        store.commit({
          type: 'UPDATE_COMMENTS_AMOUNT',
          id: route.params.idCard,
          commentsAmount: comments.value.length
        })
        detailDataLoaded.value = true
        setUpWorkFields()
      }).catch(error => {
        console.error(error)
      })
    }

    /**
     * @description Call and returns getDetailComments from storage
     */
    function callGetDetailComments () {
      return store.dispatch({
        type: 'getDetailComments',
        idCard: route.params.idCard
      })
    }

    /**
     * @description Set up not empty ordered fields to be displayed
     */
    function setUpWorkFields () {
      configuration.workDetailFields.generic.forEach((field) => {
        if (field.value === 'collaborators' && resultItemWork.value[field.value].length) {
          const collaborators = resultItemWork.value[field.value].map(collaborator => {
            return { value: collaborator.name, link: `${process.env.VUE_APP_PF_URL}profile/${collaborator.ecCode}`, ecCode: collaborator.ecCode, displayUserInfo: collaborator.displayUserInfo }
          })
          addFieldToList(field.title, collaborators)
        } else if (field.value === 'authors' && resultItemWork.value[field.value].length) {
          const authors = resultItemWork.value[field.value].map(author => {
            return { value: author.name, link: `${process.env.VUE_APP_PF_URL}profile/${author.ecCode}`, ecCode: author.ecCode, displayUserInfo: author.displayUserInfo }
          })
          addFieldToList(field.title, authors)
        } else {
          var fieldValue = resultItemWork.value.extraFields[field.value]
          fieldValue = Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue
          if (typeof (fieldValue) === 'number' ? true : fieldValue) {
            addFieldToList(field.title, fieldValue)
          }
        }
      })
      const awards = resultItemWork.value.extraFields.awards
      if (awards && awards.length > 0) {
        addFieldToList('Awards', awards)
      }
      setAssetToShow.value = itemHasStreamingUrls.value ? resultItemWork.value.assets[0].streamingUrl[0]
        : resultItemWork.value.assets[0].thumbnails.length ? resultItemWork.value.assets[0].thumbnails[0] : ''
      switch (resultItemWork.value.assets[0].assetType) {
        case 'Video':
          addFieldToListBatch(configuration.workDetailFields.video)
          break
        case 'Image':
          addFieldToListBatch(configuration.workDetailFields.image)
          break
        case 'Audio':
          addFieldToListBatch(configuration.workDetailFields.audio)
          break
        case 'Web':
          addFieldToListBatch(configuration.workDetailFields.web)
          break
        case 'Presentation':
          addFieldToListBatch(configuration.workDetailFields.slide)
          break
      }
      nextTick(() => {
        if (itemHasStreamingUrls.value) {
          const captionsArray = []
          if (resultItemWork.value.assets[0].closedCaptions) {
            resultItemWork.value.assets[0].closedCaptions.forEach((closedCaption) => {
              captionsArray.push({
                kind: 'captions',
                src: closedCaption.url,
                srclang: closedCaption.language,
                label: closedCaption.tag
              })
            })
          }
          azureMediaPlayer.value = setUpAzureMediaPlayer(setAssetToShow.value, resultItemWork.value.assets[0].mimeType, captionsArray, resultItemWork.value.assets[0].posterImage)
          setTimeout(() => {
            const muteUnmuteControl = document.getElementsByClassName('vjs-volume-control')[0]
            muteUnmuteControl.classList.remove('vjs-hidden')
          }, 100)
        }
      })
    }

    /**
     * @description Add a field to the fields array
     * @param title field title
     * @param value field value
     */
    function addFieldToList (title, value) {
      const workField = {
        title: title,
        value: value
      }
      fields.value.push(workField)
    }

    /**
     * @description Add a list of field to the fields array
     * @param configuration list of fields to be added
     */
    function addFieldToListBatch (configurarion) {
      configurarion.forEach((field) => {
        var fieldValue = resultItemWork.value.assets[0].extraFields[field.value]
        fieldValue = Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue
        if (typeof (fieldValue) === 'number' ? true : fieldValue) {
          addFieldToList(field.title, fieldValue)
        }
      })
    }

    /**
     * @description Actions on back click
     */
    function goBack () {
      if (azureMediaPlayer.value && Object.values(azureMediaPlayer.value).length > 0) azureMediaPlayer.value.dispose()
      setScrollRestoreFlag()
    }

    /**
     * @description Download file preview
     */
    function downloadFilePreview () {
      showSpinnerDownloadFile.value = true
      store.dispatch({
        type: 'work/getWorkFilePreview',
        id: route.params.idCard
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '')

        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.querySelector('.containerDetailWork').appendChild(fileLink)

        fileLink.click()
        document.querySelector('.containerDetailWork').removeChild(fileLink)
        showSpinnerDownloadFile.value = false
      })
    }

    /**
     * @description Open collectons modal
     * @param id From card
     * @param flag true or false to know if modal is open or close
     */
    function openCollectionsModal (id, flag) {
      store.commit({
        type: 'SET_COLLECTION_INFO',
        id: id,
        flag: flag
      })
    }

    /**
     * @description Open/Close likes modal
     * @param flag flag to indicate if open or close
     */
    function toggleCreateCollectionsModal (flag) {
      createCollectionModalVisible.value = flag
    }

    /**
     * @description Go Back to collection Modal and reload collections
     */
    function backToCollectionModal () {
      store.dispatch({
        type: 'getCollections',
        ids: [resultItemWork.value.id]
      }).then(
        (response) => {
          toogleCollectionsModal(resultItemWork.value.id, true)
          toggleCreateCollectionsModal(false)
        }
      )
    }

    /**
     * @description Go to Create collectionModal
     */
    function forwardToCreateCollectionModal () {
      toogleCollectionsModal(null, false)
      toggleCreateCollectionsModal(true)
    }

    /**
     * @description Toogle CollectionsModal visibility
     * @param id of the card that will be added.
     * @param flag to control open or close modal.
     */
    function toogleCollectionsModal (id, flag) {
      store.commit({
        type: 'SET_COLLECTION_INFO',
        id: id,
        flag: flag
      })
    }

    /**
     * @description open add to my portfolio modal confirmation.
     * @param {mode} string to indicate if message will be about removing or adding an item.
     */
    function openAddToPortfolioModal (mode) {
      portfolioModal.value.open(mode)
    }

    /**
     * @description Open delete work item confirmation modal
     */
    function openDeleteModal () {
      deleteWorkItemModal.value.open(resultItemWork.value.id)
    }

    /**
     * @description Open/Close likes modal
     * @param flag flag indicating if open or close
     */
    function toggleLikesModal (flag) {
      likesModalVisible.value = flag
    }

    /**
     * @description show toast with delete item fail message.
     */
    function showDeleteErrorToast () {
      deleteErrorToast.value = true
      setTimeout(() => {
        deleteErrorToast.value = false
      }, 5000)
    }

    /**
     * @description Updates the list of comments when user adds new comments.
     */
    function updateCommentList () {
      callGetDetailComments().then(response => {
        comments.value = response.data.comments
        store.commit({
          type: 'UPDATE_COMMENTS_AMOUNT',
          id: route.params.idCard,
          commentsAmount: comments.value.length
        })
      })
    }

    /**
     * @description update comment content with edited value
     * @param commentEdited new comment text
     * @param commentDate new comment date
     */
    function updateCommentContent (commentEdited, commentDate, index) {
      comments.value[index].content = commentEdited
      comments.value[index].date = commentDate
    }

    /**
     * @description Sets local store flag to indicate if restore or not scroll position
     */
    function setScrollRestoreFlag () {
      localStorage.setItem('restoreScrollTop', true)
    }

    /**
     * @description Download selected file
     * @param file Selected file
     * @param index index of selected file
     */
    function downloadFile (file, index) {
      downloadingSupporting.value.push(index)
      fetch(file.permanentSasUrl).then((response) => {
        response.blob().then((data) => {
          const fileDownloaded = new File([data], file.originalName, {
            type: response.headers.get('content-type')
          })

          const fileURL = window.URL.createObjectURL(fileDownloaded)
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', file.originalName)
          document.querySelector('.containerDetailWork').appendChild(fileLink)

          fileLink.click()
          document.querySelector('.containerDetailWork').removeChild(fileLink)
          const indexDelete = downloadingSupporting.value.indexOf(index)
          if (indexDelete !== -1) {
            downloadingSupporting.value.splice(indexDelete, 1)
          }
        })
      })
    }

    /**
     * @description Updates the collection data to check if it contains item id
     * @param collectionIndex index of the collection to update
     */
    function updateCollectionContainId (collectionIndex) {
      store.commit({
        type: 'ADD_REMOVE_ITEM_FROM_COLLECTION',
        positionCollection: collectionIndex
      })
    }

    return {
      store,
      router,
      route,
      fields,
      resultItemWork,
      detailDataLoaded,
      setAssetToShow,
      errorMessageToast,
      deleteErrorToast,
      azureMediaPlayer,
      comments,
      likesModalVisible,
      portfolioModal,
      deleteWorkItemModal,
      firstHalf,
      secondHalf,
      elegibleForGreyCom,
      goBack,
      downloadFilePreview,
      openCollectionsModal,
      openAddToPortfolioModal,
      openDeleteModal,
      toggleLikesModal,
      showDeleteErrorToast,
      updateCommentList,
      updateCommentContent,
      itemHasStreamingUrls,
      showSpinnerDownloadFile,
      backToCollectionModal,
      forwardToCreateCollectionModal,
      createCollectionModalVisible,
      toggleCreateCollectionsModal,
      fileIndexActive,
      downloadFile,
      downloadingSupporting,
      showSpinnerSupporting,
      showDownloadSupporting,
      updateCollectionContainId,
      scrollToTop,
      scrollY
    }
  }
}
</script>
